import { SvgIcon } from '@mui/material'

const PlayIconAtom = () => {
	return (
		<SvgIcon viewBox="0 0 37 38" width={37} height={38}>
			<path fill="currentColor" d="M0.42749 37.9492V0.732422L36.665 17.3821L0.42749 37.9492Z" />
		</SvgIcon>
	)
}

export default PlayIconAtom
